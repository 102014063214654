<script>

export default {
  name: "PosView"
}
</script>

<template>
<router-view></router-view>
</template>

<style scoped lang="scss">

</style>